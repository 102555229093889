
import Vue from '@/vueTyped';
import TimeRangePicker from '@/components/TimeRangePicker.vue';
import { Neighborhood } from './Index.vue';
import TransitionExpand from '@/components/TransitionExpand.vue';

type PeopleQuery = {
    group?: string;
    region?: string;
    activity?: string,
    last?: string;
    after?: string;
    before?: string;
    neighborhood?: string;
    contributionsMin?: string;
};

export default Vue.extend({
    components: {
        TimeRangePicker,
        TransitionExpand,
    },

    props: {
        query: {
            type: Object,
            default: () => ({}),
        },

        neighborhoods: {
            type: Array as () => Neighborhood[],
            default: () => [],
        },
    },

    data() {
        return {
            filters: {
                activity: 'posting',
                relativeDates: true,
                last: 'ever' as string,
                after: null as string | null,
                before: null as string | null,
                neighborhood: null as string | null,
                contributionsMin: 0 as number,
            },
        };
    },

    computed: {
        activityOptions(): { value: string, text: string }[] {
            return [
                { value: 'posting', text: 'Posted' },
                { value: 'registered', text: 'Signed up' },
                { value: 'commenting', text: 'Commented' },
                { value: 'inactive', text: 'None' }
            ];
        },

        neighborhoodOptions(): (Neighborhood | (Omit<Neighborhood, 'id'> & { id: null }))[] {
            return [
                { id: null, name: '(Any neighborhood)' },
                ...this.neighborhoods,
            ];
        },

        externalValue(): PeopleQuery {
            const activityParams = {
                activity: undefined as string | undefined,
                last: undefined as string | undefined,
                after: undefined as string | undefined,
                before: undefined as string | undefined,
            };

            if (this.filters.activity) {
                activityParams.activity = this.filters.activity;

                if (this.filters.relativeDates) {
                    activityParams.last = this.filters.last;
                } else if (this.filters.after || this.filters.before) {
                    activityParams.after = this.filters.after ?? undefined;
                    activityParams.before = this.filters.before ?? undefined;
                } else {
                    activityParams.last = 'ever';
                }
            }

            return {
                ...activityParams,
                neighborhood: this.filters.neighborhood ?? undefined,
                contributionsMin: this.filters.contributionsMin === 0 ? undefined : String(this.filters.contributionsMin),
            };
        },
    },

    watch: {
        query: {
            deep: true,
            immediate: true,
            handler(query) {
                this.syncFiltersWithQuery(query);
            },
        },

        externalValue: {
            deep: true,
            handler(externalValue) {
                this.$emit('input', externalValue);
            },
        },
    },

    methods: {
        syncFiltersWithQuery(query: PeopleQuery) {
            this.filters.activity = query.activity ?? this.activityOptions[0].value;
            this.filters.relativeDates = !(query.after || query.before);
            this.filters.last = query.last ?? 'ever';
            this.filters.after = query.after ?? null;
            this.filters.before = query.before ?? null;
            this.filters.neighborhood = query.neighborhood ?? null;
            this.filters.contributionsMin = query.contributionsMin ? parseFloat(query.contributionsMin) : 0;
        },

        // Called by the parent, which is bad, so make it ugly.
        publicSyncFiltersWithQuery(query: PeopleQuery) {
            return this.syncFiltersWithQuery(query);
        },
    },
});
