import { render, staticRenderFns } from "./ActionFooter.vue?vue&type=template&id=4220687c&scoped=true"
import script from "./ActionFooter.vue?vue&type=script&lang=ts"
export * from "./ActionFooter.vue?vue&type=script&lang=ts"
import style0 from "./ActionFooter.vue?vue&type=style&index=0&id=4220687c&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4220687c",
  null
  
)

export default component.exports