
import { RadioCheckboxSurveyQuestion, Survey, SurveyQuestion, TextSurveyQuestion } from '@/types';
import ContentBlock from '@/ui/ContentBlock.vue';
import ContentBox from '@/ui/ContentBox.vue';
import IdProvider from '@/ui/IdProvider.vue';
import LayoutColumn from '@/ui/LayoutColumn.vue';
import LayoutRow from '@/ui/LayoutRow.vue';
import Vue from '@/vueTyped';

const TEMPORARY_KEYS = new WeakMap<object, number>();

function randomId(length = 24) {
    return [
        Math.random().toString(36).split('.')[1],
        Math.random().toString(36).split('.')[1],
        Math.random().toString(36).split('.')[1]
    ].join('').slice(0, length);
}

export default Vue.extend({
    components: {
        ContentBlock,
        ContentBox,
        IdProvider,
        LayoutColumn,
        LayoutRow,
    },

    props: {
        surveyId: { type: String as () => Survey['id'], required: true },
    },

    data() {
        return {
            survey: null as Survey | null,
            questionOptionsCache: {} as Record<SurveyQuestion['id'], {
                text?: TextSurveyQuestion['options'],
                radioCheckbox?: RadioCheckboxSurveyQuestion['options']
            }>
        };
    },

    computed: {
        currentState(): string {
            const { title, description, settings } = this.survey!;
            const surveyQuestions = this.survey!.surveyQuestions!.map(question => {
                const { order, title, subtitle, is_required, on_condition, question_type, options } = question;
                return { order, title, subtitle, is_required, on_condition, question_type, options };
            });
            return JSON.stringify({ title, description, settings, surveyQuestions }, null, 2);
        },
    },

    watch: {
        surveyId: {
            immediate: true,
            handler() {
                this.fetchSurvey();
            },
        },

        'survey.surveyQuestions': {
            immediate: true,
            deep: true,
            handler() {
                this.syncQuestionOrders();
                this.syncQuestionOptions();
            },
        },
    },

    methods: {
        async fetchSurvey() {
            const { data } = await this.$store.state.apiClient.get(`/surveys/${this.surveyId}`);
            const survey: Survey = data.survey;
            survey.surveyQuestions ??= [];
            survey.surveyQuestions.sort((q1, q2) => (q1.order ?? 0) - (q2.order ?? 0));
            this.survey = survey;
        },

        syncQuestionOrders() {
            this.survey?.surveyQuestions?.forEach((q, i) => q.order = i);
        },

        syncQuestionOptions() {
            this.survey?.surveyQuestions?.forEach(question => {
                this.questionOptionsCache[question.id] ??= {};

                if (question.question_type === 'text') {
                    this.questionOptionsCache[question.id].text ??= { multiline: false };
                    if (!Array.isArray(question.options)) {
                        this.questionOptionsCache[question.id].text = question.options;
                    } else {
                        this.questionOptionsCache[question.id].radioCheckbox = question.options;
                        question.options = this.questionOptionsCache[question.id].text!;
                    }
                }

                if (question.question_type === 'radio' || question.question_type === 'checkbox') {
                    this.questionOptionsCache[question.id].radioCheckbox ??= [];
                    if (Array.isArray(question.options)) {
                        this.questionOptionsCache[question.id].radioCheckbox = question.options;
                    } else {
                        this.questionOptionsCache[question.id].text = question.options;
                        question.options = this.questionOptionsCache[question.id].radioCheckbox!;
                    }
                }
            });
        },

        moveQuestion(question: SurveyQuestion, shift: number) {
            this.moveItem(this.survey?.surveyQuestions!, question, shift);
        },

        addQuestion() {
            this.survey!.surveyQuestions!.push({
                id: randomId(),
                order: this.survey!.surveyQuestions!.length,
                survey_id: this.surveyId,
                title: '',
                subtitle: '',
                is_required: false,
                on_condition: null,
                question_type: 'text',
                options: { multiline: false },
            });
        },

        deleteQuestion(question: SurveyQuestion) {
            for (const otherQuestion of this.survey!.surveyQuestions!) {
                if (
                    (
                        otherQuestion.on_condition?.[0] === 'ANSWER_EQUALS' ||
                        otherQuestion.on_condition?.[0] === 'ANSWERED_AFFIRMATIVE'
                    ) &&
                    otherQuestion.on_condition.includes(question.id)
                ) {
                    otherQuestion.on_condition = null;
                }
            }
            const index = this.survey!.surveyQuestions!.indexOf(question);
            this.survey!.surveyQuestions!.splice(index, 1);
        },

        addOption(question: RadioCheckboxSurveyQuestion) {
            if (Array.isArray(question.options)) question.options.push({ value: '', label: '' });
        },

        deleteOption(question: RadioCheckboxSurveyQuestion, option: NonNullable<typeof question.options>[number]) {
            for (const otherQuestion of this.survey!.surveyQuestions!) {
                if (
                    (
                        otherQuestion.on_condition?.[0] === 'ANSWER_EQUALS' ||
                        otherQuestion.on_condition?.[0] === 'ANSWERED_AFFIRMATIVE'
                    ) &&
                    otherQuestion.on_condition.includes(question.id) &&
                    otherQuestion.on_condition.includes(option.value)
                ) {
                    otherQuestion.on_condition = null;
                }
            }
            const index = question.options!.indexOf(option);
            question.options!.splice(index, 1);
        },

        moveItem(items: unknown[], item: SurveyQuestion, shift: number) {
            const index = items.indexOf(item);
            items.splice(index, 1);
            items.splice(index + shift, 0, item);
        },

        getKeyFor(object: object) {
            if (!TEMPORARY_KEYS.has(object)) TEMPORARY_KEYS.set(object, Math.random());
            return TEMPORARY_KEYS.get(object);
        },
    },
});
