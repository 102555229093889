
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import OverlayModal from '@/layouts/OverlayModal.vue';
import { ClientGroup, Region, User } from '@/types';
import MinWidthScroller from '@/ui/MinWidthScroller.vue';
import { getMomentFromNow } from '@/util.app';
import Vue from '@/vueTyped';
import FiltersForm from './FiltersForm.vue';
import PeopleTable from './PeopleTable.vue';
import QueryDescription from './QueryDescription.vue';

const RESULTS_PER_PAGE = 50;

const ACTIVITY_TYPES = [ 'registered', 'posting', 'commenting', 'inactive'] as const;

export type Neighborhood = { id: string, name: string };

export type Person = {
    avatar: User['avatar'];
    first_name: User['firstName'];
    last_name: User['lastName'];
    last_participated_at: string;
    participation_count: number;
    registered_at: string;
    user_id: User['id'];
};

export default Vue.extend({
    components: {
        FiltersForm,
        LoadingIndicator,
        MinWidthScroller,
        OverlayModal,
        PeopleTable,
        QueryDescription,
    },

    props: {
        clientGroup: { type: Object as () => ClientGroup, required: true },
    },

    data() {
        return {
            fetching: 0,
            regions: [] as { id: Region['id'], name: Region['label'] }[],
            neighborhoods: [] as Neighborhood[],
            latestUsersResponse: { total: 0, limit: 0, users: [] } as { total: number, limit: number, users: Person[] },
            filtersOpen: false,
            filters: {},
        };
    },

    computed: {
        selectedRegion(): Region | null {
            if (this.clientGroup.regions.length === 1) {
                return this.clientGroup.regions[0];
            }
            return this.clientGroup.regions.find(region => {
                return region.id === this.$route.query.region;
            }) ?? null;
        },

        queriedNeighborhood(): Neighborhood | null {
            return this.neighborhoods.find(n => {
                return n.id === this.$route.query.neighborhood;
         }) ?? null;
        },

        queriedActivity(): typeof ACTIVITY_TYPES[number] {
            const query = String(this.$route.query.activity);
            if (ACTIVITY_TYPES.includes(query as typeof ACTIVITY_TYPES[number])) {
                return query as typeof ACTIVITY_TYPES[number];
            } else {
                return 'posting';
            }
        },

        lastColumn(): 'posts' | 'comments' | null {
            if (['registered', 'posting'].includes(this.queriedActivity)) {
                return 'posts';
            } else if (this.queriedActivity === 'commenting') {
                return 'comments';
            } else {
                return null;
            }
        },

        sort(): string {
            return `${this.$route.query.sort ?? '-contributions'}`;
        },

        totalPageCount(): number {
            return Math.ceil(this.latestUsersResponse.total / this.latestUsersResponse.limit);
        },

        filtersFormHasFilters(): boolean {
            return Object.values(this.filters).filter(Boolean).length !== 0;
        },
    },

    watch: {
        'clientGroup.id': {
            immediate: true,
            handler() {
                this.fetchNeighborhoods();
            },
        },

        '$route.query': {
            deep: true,
            immediate: true,
            async handler() {
                await this.$store.state.account.currentSessionCheck;
                try {
                    await this.fetchUsers();
                } catch (error) {
                    this.$store.dispatch('alertUser', {
                        type: 'error',
                        message: ['There was a problem getting users', error.message].join('\n'),
                    });
                }
            },
        },
    },

    methods: {
        async fetchNeighborhoods() {
            const api = this.$store.state.apiClient;
            const { data, error } = await api.get(`/../v3/client-groups/${this.clientGroup.id}/neighborhoods/v1`);
            if (error) {
                alert(error);
                return;
            } else {
                this.neighborhoods = data.neighborhoods as Neighborhood[];
            }
        },

        async fetchUsers() {
            if (this.clientGroup === null) {
                // this.people = [];
                return;
            }

            try {
                this.fetching += 1;

                const requestQuery = new URLSearchParams();

                const page = parseFloat(String(this.$route.query.page || 1));
                requestQuery.set('offset', String((page - 1) * RESULTS_PER_PAGE));
                requestQuery.set('limit', String(this.$route.query.limit || RESULTS_PER_PAGE));

                if (this.selectedRegion) {
                    requestQuery.set('region_id', this.selectedRegion.id);
                }

                if (this.$route.query.neighborhood) {
                    requestQuery.set('neighborhood_id', String(this.$route.query.neighborhood));
                }

                if (this.sort.endsWith('signed-up')) {
                    requestQuery.set('order_by', 'registered_at');
                } else if (this.sort.endsWith('contributed')) {
                    requestQuery.set('order_by', 'last_participated_at');
                } else {
                    // By default, `this.sort.endsWith('contributions')`.
                    requestQuery.set('order_by', 'participation_count');
                }

                requestQuery.set('order_direction', this.sort.startsWith('-') ? 'desc' : 'asc');

                requestQuery.set('participation_type', String(this.queriedActivity));

                if (this.$route.query.contributionsMin) {
                    requestQuery.set('min_participations', String(this.$route.query.contributionsMin));
                }

                let { after, before } = this.$route.query;

                if (this.$route.query.last) {
                    after = getMomentFromNow(String(this.$route.query.last)).format();
                    before = '';
                }

                if (after) {
                    requestQuery.set('from_date', String(after));
                }

                if (before) {
                    requestQuery.set('to_date', String(before));
                }

                const peopleEndpoint = `/../v3/client-groups/${this.clientGroup.id}/users/v1?${requestQuery}`;
                const { error, data: response } = await this.$store.state.apiClient.get(peopleEndpoint);

                if (error) {
                    throw error;
                }

                this.latestUsersResponse = response;
            } catch (error) {
                this.latestUsersResponse = { total: 0, limit: 0, users: [] };
                console.error(error);
                throw error;
            } finally {
                this.fetching -= 1;
            }
        },

        clearFiltersForm() {
            // Calling a child's methods directly isn't great.
            (this.$refs.filtersForm as InstanceType<typeof FiltersForm>).publicSyncFiltersWithQuery({});
        },

        handleFiltersSubmission() {
            this.filtersOpen = false;

            this.$router.replace({
                query: {
                    ...this.$route.query,
                    ...this.filters,
                    page: undefined,
                },
            });
        },
    },
});
