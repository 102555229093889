
import Vue from '@/vueTyped';
import DataTableSortIndicator from './DataTableSortIndicator.vue';

export default Vue.extend({
    components: {
        DataTableSortIndicator,
    },

    props: {
        sortParam: {
            type: String,
            default: 'sort',
        },

        pageParam: {
            type: String,
            default: 'page',
        },

        head: {
            type: Array as () => { label: string, sort?: string }[],
            default: () => [],
        },

        defaultSort: {
            type: String,
            default: '',
        },

        pillRows: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {};
    },

    computed: {
        currentSort(): string {
            let defaultSort = this.defaultSort || this.head?.find(cell => cell.sort)?.sort;
            return String(this.$route.query[this.sortParam] ?? defaultSort ?? '');
        }
    },
});
