
import { ClientGroup } from '@/types';
import Vue from '@/vueTyped';

export default Vue.extend({
    i18n: {
        messages: {
            en: {
                map: 'Map',
                people: 'People',
                userGroups: 'User Groups',
                surveys: 'Surveys',
            },
        },
    },

    computed: {
        clientGroups(): ClientGroup[] {
            return this.$store.state.account.currentUser?.clientGroups ?? [];
        },

        selectedClientGroup(): ClientGroup | null {
            const clientGroupSlug = String(this.$route.params.clientGroupSlug ?? '');
            return this.clientGroups.find(cg => cg.slug === clientGroupSlug) ?? null;
        },
    },

    watch: {
        '$route.params.clientGroupSlug': {
            immediate: true,
            handler() {
                this.ensureSlugIsSet();
            },
        },

        clientGroups: {
            immediate: true,
            handler() {
                this.ensureSlugIsSet();
            },
        },
    },

    methods: {
        ensureSlugIsSet() {
            if (this.clientGroups.length > 0 && !this.selectedClientGroup) {
                this.$router.replace({
                    params: {
                        ...this.$route.params,
                        clientGroupSlug: this.clientGroups[0].slug,
                    },
                });
            }
        },
    },
});
