
import Chart from '@/components/Chart.vue';
import { Survey } from '@/types';
import BigValue from '@/ui/BigValue.vue';
import ContentBlock from '@/ui/ContentBlock.vue';
import ContentBox from '@/ui/ContentBox.vue';
import LayoutColumn from '@/ui/LayoutColumn.vue';
import LayoutRow from '@/ui/LayoutRow.vue';
import MinWidthScroller from '@/ui/MinWidthScroller.vue';
import Vue from '@/vueTyped';

export default Vue.extend({
    components: {
        Chart,
        BigValue,
        ContentBlock,
        ContentBox,
        LayoutColumn,
        LayoutRow,
        MinWidthScroller,
    },

    data() {
        return {
            OVERVIEW_ITEMS: [{ text: 'Month', value: 'month' }, { text: 'Day', value: 'day' }],
            survey: null as Survey | null,
        };
    },

    computed: {
        overviewPeriod: {
            set(overview: string) {
                this.$router.replace({ query: { overview } });
            },

            get(): string {
                return String(this.$route.query.overview ?? this.OVERVIEW_ITEMS[0].value);
            },
        },

        formattedResponses(): { label: string, value: number }[] {
            if (this.overviewPeriod === 'day') {
                const formatter = new Intl.DateTimeFormat(this.$i18n.locale, { day: 'numeric', month: 'short' });
                return this.survey?.stats?.responsesByDay.slice(0, 30).reverse().map(r => {
                    return {
                        label: formatter.format(new Date(r.date)),
                        value: r.count,
                    };
                }) ?? [];
            } else {
                const months: Record<string, number> = {};
                for (const day of this.survey?.stats?.responsesByDay ?? []) {
                    const yearMonth = day.date.split('-').slice(0, 2).join('-');
                    months[yearMonth] ??= 0;
                    months[yearMonth] += day.count;
                }

                const formatter = new Intl.DateTimeFormat(this.$i18n.locale, { month: 'short', year: 'numeric' });
                return Object.entries(months).slice(0, 12).reverse().map(([yearMonth, count]) => {
                    const [year, month] = yearMonth.split('-');
                    const label = formatter.format(Date.UTC(parseFloat(year), parseFloat(month)));
                    return { label, value: count };
                });
            }
        },

        formattedQuestions(): any[] {
            return this.survey?.stats?.questions.slice().sort((q1, q2) => {
                const q1Order = this.survey?.surveyQuestions?.find(q => q.id === q1.id)?.order ?? 0;
                const q2Order = this.survey?.surveyQuestions?.find(q => q.id === q2.id)?.order ?? 0;
                return Math.sign(q1Order - q2Order);
            }).map(question => {
                return {
                    ...question,
                    answers: question.answers.map(answer => {
                        return {
                            label: [answer.answer, `**${answer.count}**`].join('\n'),
                            value: answer.count,
                            color: `#${Math.random().toString(16).split('.')[1].slice(0, 6)}`,
                        };
                    }),
                };
            }) ?? [];
        },
    },

    watch: {
        '$route.params.surveyId': {
            immediate: true,
            handler(surveyId) {
                this.fetchSurvey(surveyId);
            },
        },
    },

    methods: {
        async fetchSurvey(surveyId: Survey['id']) {
            const { data: { survey } } = await this.$store.state.apiClient.get(`/surveys/${surveyId}`);
            this.survey = survey;
        },
    },
});
