
import LayoutColumn from '@/ui/LayoutColumn.vue';
import LayoutRow from '@/ui/LayoutRow.vue';
import Vue from '@/vueTyped';
import type { Chart, ChartData, ChartOptions } from 'chart.js';
import MarkdownOutput from './MarkdownOutput.vue';

// Most people won't use this, so we'll load it on the fly.
let ImportingChartJs: Promise<typeof Chart> | null = null;
async function importChartJs() {
    ImportingChartJs ??= import('chart.js').then(({ Chart, registerables }) => {
        Chart.register(...registerables);
        return Chart;
    });
    return ImportingChartJs;
}

export default Vue.extend({
    components: {
        LayoutRow,
        LayoutColumn,
        MarkdownOutput
    },

    props: {
        type: { type: String as () => 'bar' | 'pie', default: 'bar' },
        data: { type: Array as () => { label: string, value: number, color?: string }[], default: () => [] },
        defaultFill: { type: String, default: 'var(--color-secondary)' },
    },

    data() {
        return {
            chart: null as Chart | null
        };
    },

    computed: {
        currentColor(): string {
            const canvas = this.$refs.canvas as HTMLCanvasElement;
            return getComputedStyle(canvas).color;
        },

        defaultFillColor(): string {
            const defaultFillStub = this.$refs.defaultFillStub as HTMLSpanElement;
            return getComputedStyle(defaultFillStub).color;
        },

        defaultOptions(): { bar: ChartOptions<'bar'>, pie: ChartOptions<'pie'> } {
            return {
                bar: {
                    scales: {
                        x: {
                            grid: { color: 'transparent' },
                            ticks: { color: this.currentColor },
                        },
                        y: {
                            grid: { color: '#9992' },
                            ticks: {
                                color: this.currentColor,
                                stepSize: this.allValuesAreIntegers ? 1 : undefined,
                            },
                        },
                    },
                    plugins: {
                        legend: { display: false },
                    },
                },

                pie: {
                    animation: {
                        duration: 0,
                    },
                    plugins: {
                        legend: { display: false },
                    },
                }
            };
        },

        allValuesAreIntegers(): boolean {
            return this.data.every(datum => datum.value % 1 === 0);
        },
    },

    watch: {
        type: 'reset',
        data: 'reset',
    },

    mounted() {
        this.createChart();
    },

    beforeDestroy() {
        this.destroyChart();
    },

    methods: {
        async createChart() {
            const canvas = this.$refs.canvas as HTMLCanvasElement;

            const type = this.type as 'bar' | 'pie';

            const data: ChartData = {
                labels: this.data.map(d => d.label),
                datasets: [{
                    data: this.data.map(d => d.value),
                    backgroundColor: this.data.map(d => d.color ?? this.defaultFillColor),
                    borderRadius: type === 'bar' ? 5 : 0,
                    borderWidth: 0,
                    barPercentage: 0.5,
                }]
            };

            const options = this.defaultOptions[type];

            const Chart = await importChartJs();

            // @ts-ignore TODO: Reconcile these types somehow.
            this.chart = new Chart!(canvas, { type, data, options });
        },

        destroyChart() {
            this.chart?.destroy();
        },

        reset() {
            this.destroyChart();
            this.createChart();
        }
    },
});
