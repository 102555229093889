import { render, staticRenderFns } from "./OverlayModal.vue?vue&type=template&id=161ca68a&scoped=true"
import script from "./OverlayModal.vue?vue&type=script&lang=ts"
export * from "./OverlayModal.vue?vue&type=script&lang=ts"
import style0 from "./OverlayModal.vue?vue&type=style&index=0&id=161ca68a&prod&lang=postcss"
import style1 from "./OverlayModal.vue?vue&type=style&index=1&id=161ca68a&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "161ca68a",
  null
  
)

export default component.exports