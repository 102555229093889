
import DataTable from '@/components/DataTable.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import { ClientGroup, UserGroup } from '@/types';
import MinWidthScroller from '@/ui/MinWidthScroller.vue';
import Vue from '@/vueTyped';

export default Vue.extend({
    components: {
        DataTable,
        LoadingIndicator,
        MinWidthScroller,
    },

    props: {
        clientGroup: { type: Object as () => ClientGroup, required: true },
    },

    data() {
        return {
            loading: 0,
            error: null as Error | null,
            userGroups: [] as UserGroup[],
        };
    },

    computed: {
        ownedUserGroupIds(): UserGroup['id'][] {
            const groups = this.$store.state.account.currentUser?.userGroups ?? [];
            return groups.filter(ug => ug.is_admin || ug.is_owner).map(ug => ug.id);
        },

        totalActiveSurveyCount(): number {
            return this.userGroups.reduce((total, ug) => total + ug.activeSurveyCount, 0);
        },

        groupsOwnedByClient(): UserGroup[] {
            return this.userGroups.filter((ug: UserGroup) => {
                const isInCurrentClientGroup = ug.owners?.find(owner => {
                    return owner.clientGroup?.id === this.clientGroup.id;
                });
                return isInCurrentClientGroup;
            });
        },

        ownedGroupsWithNoClient(): UserGroup[] {
            return this.userGroups.filter((ug: UserGroup) => {
                const isOwnedByUser = this.ownedUserGroupIds.includes(ug.id);
                const hasNoClientGroup = !ug.owners?.find(owner => owner.clientGroup);
                return isOwnedByUser && hasNoClientGroup;
            });
        },
    },

    watch: {
        '$store.state.account.currentUser.id': {
            immediate: true,
            handler: 'fetchUserGroups',
        },

        'clientGroup.id': {
            immediate: true,
            handler: 'fetchUserGroups',
        },
    },

    methods: {
        async fetchUserGroups() {
            try {
                this.loading += 1;
                this.userGroups = [];
                const { data } = await this.$store.state.apiClient.get('/user-groups');
                this.userGroups = data.userGroups;
            } catch (error) {
                this.error = error;
            } finally {
                this.loading -= 1;
            }
        }
    },
});
