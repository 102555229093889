
import Vue from '@/vueTyped';
import DataTableSortIndicator from '../../../components/DataTableSortIndicator.vue';
import { Person } from './Index.vue';

export default Vue.extend({
    components: {
        DataTableSortIndicator,
    },

    props: {
        sort: {
            type: String,
            default: '',
        },

        people: {
            type: Array as () => Person[],
            default: () => []
        },

        lastColumn: {
            type: String as () => 'posts' | 'comments' | null,
            default: null,
        },

        loading: {
            type: Boolean,
            default: false,
        },
    },
});
