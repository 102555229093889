
import Vue from '@/vueTyped';

export default Vue.extend({
    props: {
        tag: { type: String, default: 'div' },
        gap: { type: String, default: 'var(--spacing-8)' },
        wrap: { type: Boolean, default: false },
        align: { type: String, default: null },
        justify: { type: String, default: null },
    },

    computed: {
        style(): Record<string, string | null> {
            return {
                '--gap': String(this.gap) === '0' ? '0px' : this.gap,
                flexWrap: this.wrap ? 'wrap' : null,
                alignItems: this.align,
                justifyContent: this.justify,
            };
        },
    },
});
