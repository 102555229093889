import moment from "moment";

export function getMomentFromNow(last: string, startOfUnit: false | moment.unitOfTime.DurationConstructor = false): moment.Moment {
    // A unit is required, the number of that unit is optional.
    // E.g. `day`, `week`, `2-months`, `5-years` are all valid.
    const [unit, numberOf = '1'] = last.split('-').reverse();

    let result;

    if (unit === 'ever') {
        // Ever?! Pick something long enough ago that it won't matter.
        // https://morbotron.com/video/S01E01/oN5zN4yI4CyWEVB704iqXO3G9jA=.gif
        result = moment().subtract(1000, 'years');
    } else {
        result = moment().subtract(parseFloat(numberOf), unit as moment.unitOfTime.DurationConstructor);
    }

    if (startOfUnit) {
        result.startOf(startOfUnit);
    }

    return result;
}

// Remove leading spaces from in-code Markdown.
export function unindent(multiLineString: string) {
    return multiLineString.replace(/^ +/gm, '');
}

export function listWithAnd(items: string[]) {
    if (items.length <= 2) {
        return items.join(' and ');
    } else {
        const itemHasComma = items.join('').includes(',');
        const joiner = itemHasComma ? ';' : ',';
        return `${items.slice(0, -1).join(`${joiner} `)}${joiner} and ${items[items.length - 1]}`;
    }
}


export function openFilePicker<M extends boolean>(accept: string, multiple?: M, capture?: boolean): Promise<M extends true ? FileList : File>;
export function openFilePicker(accept = '*/*', multiple = false, capture?: boolean) {
    return new Promise((resolve, reject) => {
        const input = document.createElement('input');
        try {
            input.type = 'file';
            input.multiple = multiple;
            input.accept = accept;
            if (capture) {
                input.capture = 'environment';
            }
            input.onchange = () => {
                resolve(multiple ? input.files! : input.files![0]);
            };
            document.body.append(input);
            input.click();
        } catch (error) {
            reject(error);
        } finally {
            input.remove();
        }
    });
}
