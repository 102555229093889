
import ActionFooter from '@/components/ActionFooter.vue';
import Vue from '@/vueTyped';

export default Vue.extend({
    name: 'OverlayModal',
    components: {
        ActionFooter,
    },
    props: {
        value: {
            required: true,
            type: Boolean,
            default: false,
        },
        noCard: Boolean,
    },
    computed: {
        internalValue: {
            get(): boolean {
                return (this as any).value;
            },
            set(internalValue) {
                (this as any).$emit('input', internalValue);
            },
        },
    },
    methods: {
        close() {
            this.$emit('input', false);
        }
    },
});
