var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('table',{class:{ 'pill-rows': _vm.pillRows }},[(_vm.head)?_c('thead',[_c('tr',_vm._l((_vm.head),function(cell){return _c('th',{key:`${cell.label}`},[_c(cell.sort ? 'router-link' : 'span',{tag:"component",staticClass:"sort-link",attrs:{"to":{
                        query: {
                            ..._vm.$route.query,
                            [_vm.pageParam]: undefined,
                            [_vm.sortParam]: _vm.currentSort === cell.sort ? `-${cell.sort}` : cell.sort,
                        },
                    },"replace":"","data-active":[`-${cell.sort}`, cell.sort].includes(_vm.currentSort)}},[_c('data-table-sort-indicator',{attrs:{"current-sort":_vm.currentSort,"value":cell.sort,"inline":""}},[_vm._v(" "+_vm._s(cell.label)+" ")])],1)],1)}),0)]):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }