
import Vue from '@/vueTyped';

export default Vue.extend({
    props: {
        currentSort: {
            type: String,
            default: '',
        },

        value: {
            type: String,
            default: '',
        },

        inline: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        active(): boolean {
            return this.value !== '' && this.currentSort.endsWith(this.value);
        },

        reversed(): boolean {
            return this.active && this.currentSort.startsWith('-');
        },
    },
});
