
import ContentBlock from '@/ui/ContentBlock.vue';
import ContentBox from '@/ui/ContentBox.vue';
import LayoutColumn from '@/ui/LayoutColumn.vue';
import LayoutRow from '@/ui/LayoutRow.vue';
import SurveyCard from './Index/SurveyCard.vue';
import Vue from '@/vueTyped';
import { ClientGroup, Survey } from '@/types';

export default Vue.extend({
    components: {
        ContentBlock,
        ContentBox,
        LayoutColumn,
        LayoutRow,
        // eslint-disable-next-line vue/no-unused-components
        SurveyCard,
    },

    props: {
        clientGroup: { type: Object as () => ClientGroup, required: true },
    },

    data() {
        return {
            surveys: [] as Partial<Survey>[],
        };
    },

    computed: {
        activeSurveys(): any[] {
            return this.surveys.filter(s => !s.closedAt || new Date(s.closedAt).valueOf() > Date.now());
        },

        inactiveSurveys(): any[] {
            return this.surveys.filter(s => !this.activeSurveys.includes(s));
        },
    },

    watch: {
        'clientGroup.id': {
            immediate: true,
            handler: 'fetchSurveys',
        },
    },

    methods: {
        async fetchSurveys() {
            this.surveys = [];
            if (this.clientGroup) {
                const { data: { surveys } } = await this.$store.state.apiClient.get(`/surveys?clientGroupId=${this.clientGroup.id}`);
                this.surveys = surveys;
            }
        }
    },
});
