
import { Survey } from '@/types';
import BigValue from '@/ui/BigValue.vue';
import ContentBlock from '@/ui/ContentBlock.vue';
import ContentBox from '@/ui/ContentBox.vue';
import LayoutColumn from '@/ui/LayoutColumn.vue';
import LayoutRow from '@/ui/LayoutRow.vue';
import Vue from '@/vueTyped';

export default Vue.extend({
    components: {
        BigValue,
        ContentBlock,
        ContentBox,
        LayoutColumn,
        LayoutRow,
    },

    props: {
        survey: { type: Object as () => Survey, required: true },
    },
});
