
import Vue from '@/vueTyped';
import debounce from 'lodash/debounce';

export default Vue.extend({
    name: 'ActionFooter',

    props: {
        tag: { type: String, default: 'footer' },
    },

    data() {
        return {
            hasUnderscroll: false,
        };
    },

    mounted(this: any) {
        window.addEventListener('scroll', this.handleAnyScroll, true);
        window.addEventListener('resize', this.handleResize);
        this.checkForUnderscroll();
    },

    updated() {
        this.checkForUnderscroll();
    },

    destroyed(this: any) {
        window.removeEventListener('scroll', this.handleAnyScroll, true);
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {
        handleAnyScroll: function(this: any, event: any) {
            if (event.target.contains(this.$el)) {
                this.checkForUnderscroll();
            }
        },

        handleResize: function(this: any) {
            this.checkForUnderscroll();
        },

        checkForUnderscroll: debounce(function(this: any) {
            // Compare the position of a non-sticky element to the sticky one's.
            // TODO: Clean this up.
            const trackerBounds = (this.$refs?.tracker as HTMLDivElement | undefined)?.getBoundingClientRect();
            const contentBounds = (this.$refs?.content as HTMLDivElement | undefined)?.getBoundingClientRect();
            this.hasUnderscroll = (trackerBounds?.top ?? 0) - (contentBounds?.top ?? 0) > 1;
        }, 125),
    },
});
