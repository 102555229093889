
import Vue from '@/vueTyped';

export default Vue.extend({
    props: {
        tag: { type: [String, Function], default: 'div' },
    },

    data() {
        return {
            id: Math.random().toString(36).split('.')[1],
        };
    },
});
