
import Vue from '@/vueTyped';
import BigValue from '@/ui/BigValue.vue';
import ContentBlock from '@/ui/ContentBlock.vue';
import DataTable from '@/components/DataTable.vue';
import { Survey, UserGroup, UserGroupMember } from '@/types';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import MinWidthScroller from '@/ui/MinWidthScroller.vue';
import { CLIENT_ORIGIN } from '@/config';

export default Vue.extend({
    components: {
        BigValue,
        ContentBlock,
        DataTable,
        LoadingIndicator,
        MinWidthScroller,
    },

    props: {
        userGroupId: { type: String, required: true },
    },

    data() {
        return {
            loadingUserGroup: 0,
            userGroupLoadError: null as Error | null,
            userGroup: null as UserGroup | null,
            loadingSurveys: 0,
            surveysLoadError: null as Error | null,
            surveys: [] as Survey[],
            loadingMembers: 0,
            membersLoadError: null as Error | null,
            members: [] as UserGroupMember[],
            generatingInviteToken: 0,
            invitationLinkCopied: 0,
        };
    },

    computed: {
        invitationLinkExpired(): boolean {
            return new Date(this.userGroup?.inviteLink?.expiresAt ?? 0) < new Date();
        },

        invitationLinkHref(): string | null {
            const inviteToken = this.userGroup?.inviteLink?.token;

            if (!this.userGroup || !inviteToken || this.invitationLinkExpired) {
                return null;
            }

            return CLIENT_ORIGIN + this.$router.resolve({
                name: 'join-user-group',
                params: { userGroupId: this.userGroup.id },
                hash: `#${inviteToken}`,
            }).href;
        },

        userMembers(): UserGroupMember[] {
            return this.members.filter(member => member.user?.id);
        },
    },

    watch: {
        userGroupId: {
            immediate: true,
            handler() {
                this.fetchUserGroup();
                this.fetchMembers(String(this.$route.query.sort));
                this.fetchSurveys();
            },
        },

        '$route.query.sort'(sort) {
            this.fetchMembers(String(sort) || 'activity');
        },
    },

    methods: {
        async fetchUserGroup() {
            try {
                this.loadingUserGroup += 1;
                const { data } = await this.$store.state.apiClient.get(`/user-groups/${this.userGroupId}`);
                this.userGroup = data.userGroup;
            } catch (error) {
                this.userGroupLoadError = error;
                console.error(error);
            } finally {
                this.loadingUserGroup -= 1;
            }
        },

        async fetchMembers(sort: string) {
            try {
                this.loadingMembers += 1;

                if (sort.startsWith('-')) {
                //     sort = `${sort.slice(1)} DESC`;
                }

                // TODO: convert `sort` query value into the one the API expects.

                const { data } = await this.$store.state.apiClient.get(`/user-groups/${this.userGroupId}/members?sort=${'createdAt'}`);
                this.members = data.userGroupMembers;
            } catch (error) {
                this.membersLoadError = error;
                console.error(error);
            } finally {
                this.loadingMembers -= 1;
            }
        },

        async fetchSurveys() {
            try {
                this.loadingSurveys += 1;
                const { data } = await this.$store.state.apiClient.get(`/surveys?userGroupId=${this.userGroupId}`);
                this.surveys = data.surveys;
            } catch (error) {
                this.surveysLoadError = error;
                console.error(error);
            } finally {
                this.loadingSurveys -= 1;
            }
        },

        async generateInvitationLink() {
            if (!this.userGroup) {
                throw new Error('Attempted to generate an invite link without a user group');
            }

            this.generatingInviteToken += 1;
            const { data } = await this.$store.state.apiClient.post(`/user-groups/${this.userGroupId}/invite-token/generate`);
            this.$set(this.userGroup, 'inviteLink', data.inviteLink);
            this.generatingInviteToken -= 1;
        },

        async copyInvitationLink() {
            if (this.invitationLinkHref) {
                try {
                    await navigator.clipboard.writeText(this.invitationLinkHref);
                    this.invitationLinkCopied += 1;
                    setTimeout(() => {
                        this.invitationLinkCopied -= 1;
                    }, 1500);
                } catch (error) {
                    alert(error);
                }
            }
        },
    },
});
